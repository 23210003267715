
import React, { useState } from 'react';
import AddToCartButton from '../cart/AddToCartButton';
import './MenuModal.css';

const MenuGridItem = ({ singleProduct, handleAddProduct, handleRemoveProduct }) => {
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [targetAttribute, setTargetAttribute] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSelectedAttributes = (attributeId, attributeValue) => {
    setTargetAttribute(attributeValue);
    const newSelectedAttribute = { attributeId, attributeValue };
    setSelectedAttributes((prevAttributes) => {
      const existingAttributeIndex = prevAttributes.findIndex(
        (attribute) => attribute.attributeId === newSelectedAttribute.attributeId
      );
      if (existingAttributeIndex !== -1) {
        const updatedAttributes = [...prevAttributes];
        updatedAttributes[existingAttributeIndex] = { ...newSelectedAttribute };
        return updatedAttributes;
      } else {
        return [...prevAttributes, newSelectedAttribute];
      }
    });
  };

  const handleItemClick = (e) => {
    if (
      !e.target.classList.contains('add-to-cart-btn') &&
      !e.target.classList.contains('button-base')
    ) {
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('menu-modal-overlay')) {
      closeModal();
    }
  };

  return (
    <>
      <article
        className="menu-grid-item flex-container flex-column txt-white"
        onClick={handleItemClick}
      >
        <img
          src={singleProduct.image_s}
          srcSet={` ${singleProduct.image} 1440w, ${singleProduct.image_s} 700w`}
          sizes="(min-width: 1440px) 1440px, 700px"
          alt={`${singleProduct.name}`}
          className="menu-item-link"
        />

        <h3>{singleProduct.name}</h3>
        <div className="price">
          {singleProduct.sale === true ? (
            <section className="sale-pricing">
              <p className="price-num-before">
                <span>₽</span>
                {singleProduct.priceBefore}
              </p>
              <p className="price-num">
                <span>₽</span>
                {singleProduct.price}
              </p>
            </section>
          ) : (
            <p className="price-num">
              <span>₽</span>
              {singleProduct.price}
            </p>
          )}
          <AddToCartButton
            handleAddProduct={handleAddProduct}
            handleRemoveProduct={handleRemoveProduct}
            singleProduct={singleProduct}
            selectedAttributes={selectedAttributes}
            targetAttribute={targetAttribute}
            setTargetAttribute={setTargetAttribute}
            closeModal={closeModal} // {{ edit_1 }}
          
          />
        </div>
      </article>

      {isModalOpen && (
        <div className="menu-modal-overlay" onClick={handleOverlayClick}>
          <button className="close-modal" onClick={closeModal}>
            ×
          </button>
          <div className="menu-modal">
            <div className="modal-content">
              <img
                src={singleProduct.image}
                alt={singleProduct.name}
                className="modal-image"
              />
              <div className="modal-info">
                <h2>{singleProduct.name}</h2>
                <p className="modal-description">{singleProduct.description}</p>
                <div className="modal-details">
                  <p className="weight">{singleProduct.weight} г</p>
                  <div className="nutrition-info">
                    <p>На порцию:</p>
                    <div className="nutrition-grid">
                      <div className="nutrition-item">
                      <span>{Math.round(singleProduct.calories*singleProduct.weight/100)}</span>
                        <span>ккал</span>
                      </div>
                      <div className="nutrition-item">
                        <span>{Math.round(singleProduct.proteins*singleProduct.weight/100)}</span>
                        <span>белки</span>
                      </div>
                      <div className="nutrition-item">
                        <span>{Math.round(singleProduct.fats*singleProduct.weight/100)}</span>
                        <span>жиры</span>
                      </div>
                      <div className="nutrition-item">
                         <span>{Math.round(singleProduct.carbohydrates*singleProduct.weight/100)}</span>
                         <span>углеводы</span>
                      </div>
                    </div>
                  </div>
                </div>
                 {/* Здесть карсивый разделитель */}
                <hr className="modal-divider" />
               

                <div className="modal-price">
                  <p className="price-num">
                    {singleProduct.price}
                    <span> ₽.</span>
                  </p>
                  <AddToCartButton
                    handleAddProduct={handleAddProduct}
                    handleRemoveProduct={handleRemoveProduct}
                    singleProduct={singleProduct}
                    selectedAttributes={selectedAttributes}
                    targetAttribute={targetAttribute}
                    setTargetAttribute={setTargetAttribute}
                    closeModal={closeModal} // {{ edit_2 }}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MenuGridItem;
