import React, { useState, useEffect, useRef } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Header from './routes/landing/Header.js';
import Footer from './components/footer/Footer';
import {
  About,
  Blog,
  Cart,
  Checkout,
  Contact,
  RootSection,
  Menu,
  Payment,
  Register,
  SingleItem,
} from './routes/index';
import { allProductsData1 } from './data/AllProductsData1.js';
// import { AllCategories } from './data/AllCategories';
import CheckoutSummary from './routes/checkout/CheckoutSummary.js';
import CartTotals from './routes/cart/CartTotals.js';
// import LoginModal from './components/login/LoginModal.js';
import CartItem from './routes/cart/CartItem.js';
// import NotFound from './routes/not-found/NotFound.js';
import Refunds from './routes/refunds/Refunds.js';
import Terms from './routes/terms/Terms.js';
import Privacy from './routes/privacy/Privacy.js';
import Careers from './routes/careers/Careers.js';
import BlogPost from './routes/blog-post/BlogPost.js';
// import Profile from './routes/profile/Profile.js';
import ResetLocation from './helpers/ResetLocation.js'; 
import axios from "axios";
import Cookies from 'js-cookie';

// import vantaHalo from "vanta/src/vanta.halo";


function App() {
  const [allCategories, setAllCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState('Меню');
  const [cartItems, setCartItems] = useState([]);
  const [clearedCart, setClearedCart] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [allMenu, setAllMenu] = useState([]);
  const [productsQuantity, setProductsQuantity] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [validLogin, setValidLogin] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);
  const [loginModalWindow, setLoginModalWindow] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  // let allProductsData = []

  // async function allProductsData() {
  //    try {
  //     const response = await axios.get('http://89.104.69.113:8000/api/backend/dish/?format=json');
  //     console.log(response);
      
  //       return response.data;
      
  //   } 
  //   catch (err) {
  //     console.log(err.message)
  //     return allProductsData1;
  //   }
  // }
 
  // const allProductsData ='';
  
  
  var allProductsData = [];
  
  
  // const allProductsData5 = async () => {
  //   try {
  //     const response = await axios.get('http://89.104.69.113:8000/api/backend/dish/?format=json');
  //     console.log(response);
     
  //     if (response.status === 200) {
  //      let allProductsData = response.data;
  //       console.log(allProductsData);
  //       return true;
  //     }
  //   }
  //   catch (error) {
  //     console.log(error.message)
      
  //     const allProductsData  =allProductsData1;
  //     console.log(allProductsData);
  //     // console.log(allProductsData1);
  //     return false;
      
  //   }
  // }



  const getUser = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_USERS_URL}/${id}`);
      const body = await response.json();
      setCurrentUser(body.data[0]);
      const jsonUser = JSON.stringify(body.data[0]);
      sessionStorage.setItem('currentUser', jsonUser);
      if (response.status === 200) {
        return true;
      }
    }
    catch (err) {
      console.log(err.message)
      return false;
    }
  }

  const updateUser = async (id, user) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_USERS_URL}/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(user),
      });

      if (response.status === 200) {
        // Assuming getUser is a function that retrieves user data
        // Make sure to handle this appropriately
        const update = await getUser(id);
        if (update) {
          return true;
        }
        return true;
      } else {
        console.log("Update failed with status:", response.status);
        return false;
      }
    } catch (err) {
      console.log("Fetch error:", err.message);
      return false;
    }
  }



  useEffect(() => {
    if (sessionStorage.getItem('currentUser') !== null) {
      const user = JSON.parse(sessionStorage.getItem('currentUser'));
      setCurrentUser(user);
    }
  }, []);

  useEffect(() => {
    if (validLogin && sessionStorage.getItem('validLogin') === null) {
      sessionStorage.setItem('validLogin', true);
    }
    if (sessionStorage.getItem('validLogin') !== null) {
      setValidLogin(sessionStorage.getItem('validLogin'))
    }
  }, [validLogin])



  const activateLoginModal = () => {
    hideMenu();
    setLoginModalWindow(!loginModalWindow);
  }

  const handleLogout = () => {
    setValidLogin(false);
    hideMenu();
    setCurrentUser({});
    ResetLocation();
    setCartItems([]);
    setProductsQuantity(0);
    sessionStorage.clear();
  };

  const findMenuItem = (e) => {
    e.preventDefault();
    const inputValue = e.target.value.toLowerCase();

    // Фильтруем allMenu на стороне клиента
    const collectData = allMenu.filter(product =>
      product.name.toLowerCase().includes(inputValue)
    );

    setAllProducts(collectData);
  };



  const showModal = () => {
    setIsModalActive(!isModalActive);
  };
  const hideMenu = () => {
    setIsModalActive(false);
  };

  // const getAllCategories = async () => {
  //   setAllCategories(AllCategories); 
  // };

  // const getAllProducts = async () => {
  //   try {
  //     const response = await axios.get('https://4-20mirnyj.ru/api/backend/dish/');
      
  //     if (response.status === 200) {
  //       const data = response.data;
  //       setAllProducts(data);
  //       return true;
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //     setAllProducts(allProductsData1);
  //     return false;
  //   }
  // };

  const getAllCategoriesAndProducts = async () => {
    try {
      const response = await axios.get('https://4-20mirnyj.ru/api/backend/dish/');

      if (response.status === 200) {
        const data = response.data;

         // Фильтруем блюда, оставляя только доступные (status = available)
         const availableData = data.filter(product => product.status === 'available');

        // Извлекаем уникальные названия категорий из данных
        const uniqueCategoryNames = new Set();
        availableData.forEach((product) => {
          uniqueCategoryNames.add(product.category_name);
        });

        // Преобразуем Set в массив объектов категорий с id
        const categories = Array.from(uniqueCategoryNames)
          .map((name) => ({
            originalName: name,
            trimmedName: name.replace(/^\d+/, '').trim(), // Удаляем цифры и пробелы для отображения
          }))
          .sort((a, b) => a.originalName.localeCompare(b.originalName)) // Сортируем по оригинальному названию
          .map((item, index) => ({
            name: item.trimmedName, // Используем имя без цифр и пробелов
            id: String(index + 1), // Присваиваем id, начиная с 1
          }));

        // Добавляем категорию "Меню" в начало массива
        categories.unshift({ name: 'Меню', id: '0' });

        setAllCategories(categories);
        setAllMenu(availableData);
        setAllProducts(availableData);
        return true;
      }
    } catch (error) {
      console.log(error.message);
      setAllMenu(allProductsData1);

      // Фильтруем блюда из резервных данных, оставляя только доступные
      const availableBackupData = allProductsData1.filter(product => product.status === 'available');

      // Извлекаем уникальные названия категорий из резервных данных
      const uniqueCategoryNames = new Set();
      availableBackupData.forEach((product) => {
        uniqueCategoryNames.add(product.category_name);
      });

      // Преобразуем Set в массив объектов категорий с id
      const categories = Array.from(uniqueCategoryNames)
        .map((name) => ({
          originalName: name,
          trimmedName: name.replace(/^\d+/, '').trim(), // Удаляем цифры и пробелы для отображения
        }))
        .sort((a, b) => a.originalName.localeCompare(b.originalName)) // Сортируем по оригинальному названию
        .map((item, index) => ({
          name: item.trimmedName, // Используем имя без цифр и пробелов
          id: String(index + 1), // Присваиваем id, начиная с 1
        }));

      // Добавляем категорию "Меню" в начало массива
      categories.unshift({ name: 'Меню', id: '0' });

      setAllCategories(categories);
      setAllMenu(availableBackupData);
      setAllProducts(availableBackupData);
      return false;
    }
  };


  // CART LOGIC
  /*******************************************************/

  const CheckRepeatableProducts = (cartItems, targetProduct, userSelectedAttributes) => {

    let item;
    let productsById = cartItems.filter((item) => item.id === targetProduct.id);
    productsById.forEach((targetItem) => {
      if (MatchingAttributes(userSelectedAttributes, targetItem)) {
        item = targetItem;
      }
      if (userSelectedAttributes.length === 0) {
        item = targetItem;
      }
    });

    return item;
  };


  const MatchingAttributes = (userSelectedAttributes, targetProduct) => {

    const attributesMatch = (groupOne, groupTwo) => {
      return Object.values(groupOne)[1] === Object.values(groupTwo)[1];
    };

    let truthyValuesCounter = 0;
    let i = 0;
    while (i < userSelectedAttributes.length) {
      if (
        attributesMatch(
          userSelectedAttributes[i],
          targetProduct?.userSelectedAttributes[i]
        )
      ) {
        truthyValuesCounter += 1;
      }
      i += 1;
    }

    return truthyValuesCounter === userSelectedAttributes?.length;
  };

  const updateCartQuantity = (actionToPerfrom, productAlreadyInCart, userSelectedAttributes) => {
    const repeatableProduct = CheckRepeatableProducts(cartItems, productAlreadyInCart, userSelectedAttributes);
    const indexOfRepeatableProduct = cartItems.indexOf(repeatableProduct);

    const currentProductList = [...cartItems];
    if (actionToPerfrom === 'addProduct') {
      currentProductList[indexOfRepeatableProduct].quantity += 1;
    } else {
      currentProductList[indexOfRepeatableProduct].quantity -= 1;
    }

    return currentProductList;
  };
  const handleAddProduct = (targetProduct, userSelectedAttributes) => {

    const productAlreadyInCart = CheckRepeatableProducts(
      cartItems,
      targetProduct,
      userSelectedAttributes
    );

    let currentCartItems = [...cartItems];
    let newQuantity;
    //if product doesn't exists yet 
    if (productAlreadyInCart === undefined) {
      const itemToAdd = targetProduct;

      newQuantity = 1;

      currentCartItems.push({
        ...itemToAdd,
        userSelectedAttributes,
        quantity: newQuantity,
      });


    }
    //if product already exists
    else {
      let index;
      //if there are no attributes find index by id
      if (userSelectedAttributes.length === 0) {
        index = cartItems.findIndex(item => item.id === targetProduct.id);
      }

      //if there are attributes find index by attributes and id at the same time
      else {
        index = cartItems.findIndex(item => item.userSelectedAttributes[0]?.attributeValue === userSelectedAttributes[0].attributeValue && item.id === targetProduct.id);
      }
      // console.log(userSelectedAttributes);
      if (index !== -1) {
        newQuantity = cartItems[index].quantity;

        currentCartItems[index] = {
          ...cartItems[index],
          quantity: newQuantity + 1,
        };

      }
    }

    const totalCartQuantity = currentCartItems.reduce(
      (total, item) => total + item.quantity,
      0
    );
    const jsonUser = JSON.stringify(currentCartItems);
    sessionStorage.setItem('cartItems', jsonUser);
    setCartItems(currentCartItems);
    sessionStorage.setItem('cartQuantity', totalCartQuantity);
    setProductsQuantity(totalCartQuantity);
    successMsg();
  };

  useEffect(() => {
    if (sessionStorage.getItem('cartItems') !== null) {
      const jsonCartItems = sessionStorage.getItem('cartItems')
      const cartItems = JSON.parse(jsonCartItems);
      setCartItems(cartItems);
    };
    if (sessionStorage.getItem('cartQuantity') !== null) {
      setProductsQuantity(sessionStorage.getItem('cartQuantity'));
    };
  }, []);

  const handleRemoveProduct = (targetProduct, userSelectedAttributes) => {
    let updatedProductList;
    let repeatableProduct = CheckRepeatableProducts(
      cartItems,
      targetProduct,
      userSelectedAttributes
    );

    if (repeatableProduct.quantity > 1) {
      updatedProductList = updateCartQuantity(
        'removeProduct',
        repeatableProduct,
        userSelectedAttributes
      );
    } else {
      const products = [...cartItems];
      const indexOfProduct = products.indexOf(repeatableProduct);
      products.splice(indexOfProduct, 1);
      updatedProductList = products;
    }

    setCartItems(updatedProductList);
    const jsonUser = JSON.stringify(updatedProductList);
    sessionStorage.setItem('cartItems', jsonUser);

    if (updatedProductList.length <= 1) {
      setProductsQuantity(updatedProductList[0]?.quantity || 0);
    } else {
      const productListArray = updatedProductList.map(item => item.quantity);
      const sum = productListArray.reduce((a, b) => a + b, 0);
      sessionStorage.setItem('cartQuantity', sum);
      setProductsQuantity(sum);
    }

    if (updatedProductList.length === 0) {
      sessionStorage.setItem('cartQuantity', 0);
      setProductsQuantity(0);
    }
  };

  const clearCart = () => {
    setCartItems([]);
    setProductsQuantity(0)
    setClearedCart(true);
    sessionStorage.removeItem('cartItems');
    sessionStorage.removeItem('cartQuantity');
    ResetLocation();
  };

  const getTotalPrice = (cartItems) => {
    let total = cartItems.reduce((prevState, currentItem) => {
      const singleItemQuantity = currentItem.price * currentItem.quantity;
      return prevState + singleItemQuantity;
    }, 0);
    setTotalPayment(total.toFixed(2));
    setTaxes(((total * 10) / 100).toFixed(2));
  };

  const successMsg = () => {
    const alertMessage = document.querySelector('.success-msg')
    alertMessage.classList.add('visible')
    setTimeout(() => {
      alertMessage.classList.remove('visible')
    }, 1000)
  };



  // Other
  /*******************************************************/

  
  

  const getProductsByCategory = (category_name) => {
    // Удаляем цифры и пробелы в начале названия категории для корректного сравнения
    const trimmedCategoryName = category_name.replace(/^\d+\s*/, '');
  
    // Разделяем продукты по категориям, также удаляя цифры и пробелы из названий категорий
    const separateCategories = allMenu.reduce((acc, item) => {
      const trimmedItemCategoryName = item.category_name.replace(/^\d+\s*/, '');
      if (!acc[trimmedItemCategoryName]) {
        acc[trimmedItemCategoryName] = [];
      }
      acc[trimmedItemCategoryName].push(item);
      return acc;
    }, {});
  
    // Выбираем нужную категорию или возвращаем все продукты для категории 'Меню'
    if (trimmedCategoryName === 'Меню') {
      setAllProducts(allMenu);
    } else if (separateCategories[trimmedCategoryName]) {
      setAllProducts(separateCategories[trimmedCategoryName]);
    } else {
      setAllProducts([]); // Если категория не найдена
    }
  };





  


  useEffect(() => {
    const initializeData = async () => {
      await getAllCategoriesAndProducts();
      
      // Убедимся, что allProducts установлен перед вызовом getProductsByCategory
      if (allProducts.length > 0) {
        getProductsByCategory(activeCategory);
      }
    };

    initializeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Выполняем только при монтировании

  useEffect(() => {
    getTotalPrice(cartItems);
  }, [cartItems]);

  useEffect(() => {
    // Проверяем, что allProducts установлен
    if (allProducts.length > 0) {
      getProductsByCategory(activeCategory);
    }
  }, [activeCategory, allMenu]);

  const changeCategory = (newCategory) => {
    setActiveCategory(newCategory);
    // getProductsByCategory не нужен, так как useEffect уже отслеживает activeCategory
  };


  // const [vantaEffect, setVantaEffect] = useState(null)
  // const myRef = useRef(null)
  // useEffect(() => {
  //   if (!vantaEffect) {
  //     setVantaEffect(vantaHalo({
  //       el: myRef.current,
  //       mouseControls: true,
  //       touchControls: true,
  //       gyroControls: true,
  //       minHeight: 200.00,
  //       minWidth: 200.00,
  //       maxHeight: 15000.000,
  //       baseColor: 0x220022,
  //       backgroundColor: 0xd0d1b,
  //       amplitudeFactor: 3.00
  //     }))
      
  //   }
  //   return () => {
  //     if (vantaEffect) vantaEffect.destroy()
  //   }
  // }, [vantaEffect])
  return (
    
  // <div ref={myRef}>
     <BrowserRouter>
      <Header
        // loginModal={
        //   <LoginModal
            
        //     validLogin={validLogin}
        //     setValidLogin={setValidLogin}
        //     setLoginModalWindow={setLoginModalWindow}
        //     loginModalWindow={loginModalWindow}
        //     hideMenu={hideMenu}
        //     getUser={getUser}
        //     setCurrentUser={setCurrentUser}
        //   />
        // }
        
        activateLoginModal={activateLoginModal}
        showModal={showModal}
        isModalActive={isModalActive}
        hideMenu={hideMenu}
        handleLogout={handleLogout}
        validLogin={validLogin}
        productsQuantity={productsQuantity}
      />
      <Routes>
        <Route 
          path="/" 
          element={<RootSection allProductsData={allProducts} />}
        />

        <Route
          path="/cart"
          element={
            <Cart
              CartItem={
                <CartItem
                  clearCart={clearCart}
                  cartItems={cartItems}
                  handleAddProduct={handleAddProduct}
                  handleRemoveProduct={handleRemoveProduct}
                  cartTotals={
                    <CartTotals
                      className="cart-carttotals"
                      totalPayment={totalPayment}
                      productsQuantity={productsQuantity}
                      taxes={taxes}
                      validLogin={validLogin}
                      showModal={showModal}
                      isModalActive={isModalActive}
                      activateLoginModal={activateLoginModal}
                    />
                  }
                />
              }

              cartItems={cartItems}
              clearedCart={clearedCart}
            />
          }
        />

        <Route
          exact path="/menu"
          element={
            <Menu
              // getallProductsData={getallProductsData}
              findMenuItem={findMenuItem}
              allProducts={allProducts}
              allCategories={allCategories}
              changeCategory={changeCategory}
              handleAddProduct={handleAddProduct}
              handleRemoveProduct={handleRemoveProduct}
              activeCategory={activeCategory}
            />
          }
        />
        <Route
          path="/menu/:name"
          element={
            <SingleItem
            
              handleAddProduct={handleAddProduct}
              handleRemoveProduct={handleRemoveProduct}
            />
          }
        />
        <Route path="/contact" element={<Contact />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route path="/blog/:name" element={<BlogPost />} />
        <Route path="/about" element={<About />} />
        {/* <Route path="/register" element={validLogin ? <NotFound /> : <Register activateLoginModal={activateLoginModal} />} /> */}
        {/* <Route path="/profile" element={!validLogin ? <NotFound /> : <Profile currentUser={currentUser} getUser={getUser} handleLogout={handleLogout} updateUser={updateUser} />} /> */}
        <Route
          path="/checkout"
          element={
            <Checkout
              checkoutSummary={
                <CheckoutSummary
                  cartItems={cartItems}
                  handleAddProduct={handleAddProduct}
                  handleRemoveProduct={handleRemoveProduct}
                />
              }
              totalPayment={totalPayment}
              cartItems={cartItems}
              productsQuantity={productsQuantity}
              taxes={taxes}
              currentUser={currentUser}
            />
          }
        />
        <Route
          path="/payment"
          element={
            <Payment
              cartItems={cartItems}
              totalPayment={totalPayment}
              currentUser={currentUser}
            />
          }
        />
        <Route path="/careers" element={<Careers />} />
        {/* <Route path="*" element={<NotFound />} /> */}
        <Route path="/refunds" element={<Refunds />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  // </div>
  );
}

export default App;
