import React from 'react'
import Telegram from '../../assets/images/social/telegram.svg'
import Instagram from '../../assets/images/social/instagram.svg'
import Vk from '../../assets/images/social/vk.svg'
import dprofile from "../../assets/images/socials/behance.svg";

export default class FooterContact extends React.Component {
  render() {
    return (
      <section className="footer-contact">
        <div className="contact-info">
          <div className="phones">
          <a href="tel:+79991680416"><p>+7 999 16 804 16</p></a>
          <a href="tel:+78183452062"><p>+7 81834 5 20 62</p></a>
          </div>
          
          <div className="working-hours">
            <p>Часы работы:</p>
            <p>Пн-Чт 12:00 - 2:00</p>
            <p>Пт-Сб 12:00 - 6:00</p>
            <p>Вс 12:00 - 2:00</p>
          </div>
        </div>

        <div className="address-info">
          <p>Архангельская обл., г. Мирный,</p>
          <p>улица Гагарина, 1А</p>
          <p>4-20hookah@mail.ru</p> 
          <div className="social-links">
            <a href="https://instagram.com/4.20_mirnyj" target="_blank" rel="noopener noreferrer">
              <img src={Instagram} alt="Instagram" />
            </a>
            <a href="https://t.me/ivxxmirnyj" target="_blank" rel="noopener noreferrer">
              <img src={Telegram} alt="Telegram" />
            </a>
            <a href="https://vk.com/iv.xx_mirnyj" target="_blank" rel="noopener noreferrer">
              <img src={Vk} alt="VK" />
            </a>
            <a href="https://dprofile.ru/arrancore" target="_blank" rel="noopener noreferrer">
              <img src={dprofile} alt="Dprofile" />
            </a>
          </div>
        </div>
      </section>
    )
  }
}
