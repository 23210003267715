import React from "react";

const AddToCartButton = ({ singleProduct,
  selectedAttributes,
  handleAddProduct, targetAttribute, setTargetAttribute, closeModal }) => {
  return (
    console.log(singleProduct.status),
    <button
      onClick={() => {
        
        handleAddProduct(singleProduct, selectedAttributes);
        setTargetAttribute(false);
        if (closeModal) {
          closeModal();
        }
      }}
      className={`button-base ${singleProduct.status== "available"
        ? "button-secondary"
        : "button-disabled"
        }`}
        
         disabled={singleProduct.status!== "available"}
    > 
      В заказ!
    </button>
  ); 
}
export default AddToCartButton;