import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const DishCarousel = ({ allProductsData }) => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Функция для перемешивания массива
  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  // Загрузка и перемешивание изображений при инициализации
  useEffect(() => {
    if (allProductsData && Array.isArray(allProductsData)) {
      const imageUrls = allProductsData
        .filter(item => item.image_s)
        .map(item => item.image_s);

      if (imageUrls.length > 0) {
        const shuffledImages = shuffleArray(imageUrls);
        setImages(shuffledImages);
        setIsLoading(false);
      } else {
        setIsLoading(false); // Убедимся, что загрузка завершается, даже если нет изображений
      }
    } else {
      setIsLoading(false); // Завершить загрузку, если данные отсутствуют
    }
  }, [allProductsData]);

  // Автоматическое переключение слайдов (теперь просто по порядку)
  useEffect(() => {
    if (isLoading || !images.length || isTransitioning) return;

    const timer = setInterval(() => {
      setIsTransitioning(true);
      setCurrentIndex(current => 
        current === images.length - 1 ? 0 : current + 1
      );

      setTimeout(() => setIsTransitioning(false), 500);
    }, 3000);

    return () => clearInterval(timer);
  }, [currentIndex, isTransitioning, images.length, isLoading]);

  const nextSlide = () => {
    if (isTransitioning || !images.length) return;

    setIsTransitioning(true);
    setCurrentIndex(current => 
      current === images.length - 1 ? 0 : current + 1
    );

    setTimeout(() => setIsTransitioning(false), 1500);
  };

  const prevSlide = () => {
    if (isTransitioning || !images.length) return;

    setIsTransitioning(true);
    setCurrentIndex(current => 
      current === 0 ? images.length - 1 : current - 1
    );

    setTimeout(() => setIsTransitioning(false), 500);
  };

  const getPrevIndex = () => {
    return currentIndex === 0 ? images.length - 1 : currentIndex - 1;
  };

  const getNextIndex = () => {
    return currentIndex === images.length - 1 ? 0 : currentIndex + 1;
  };

  if (isLoading) {
    return (
      <div className="dish-carousel-section">
        <div>Загрузка...</div>
      </div>
    );
  }

  if (!images.length) {
    return (
      <div className="dish-carousel-section">
        <div>Нет доступных изображений</div>
      </div>
    );
  }

  return (
    <div className="dish-carousel-section">
      <div className="working-hours">
        <h3 className="working-hours-title">ПРИНИМАЕМ ЗАКАЗЫ:</h3>
        <div className="working-hours-schedule">
          <p>пн-чт 12:00 - 00:00</p>
          <p>пт-вс 13:00 - 02:00</p>
        </div>
      </div>

      <div className="dish-carousel-container">
        <div className={`slide prev-slide ${isTransitioning ? 'transitioning prev' : ''}`}>
          <img 
            src={images[getPrevIndex()]} 
            alt="Предыдущее блюдо"
            loading="lazy"
          />
        </div>

        <button 
          className="carousel-button prev" 
          onClick={prevSlide}
          aria-label="Предыдущее изображение"
        >
          <FaChevronLeft />
        </button>

        <div className={`slide current-slide ${isTransitioning ? 'transitioning current' : ''}`}>
          <img 
            src={images[currentIndex]} 
            alt="Текущее блюдо"
            loading="lazy"
          />
        </div>

        <button 
          className="carousel-button next" 
          onClick={nextSlide}
          aria-label="Следующее изображение"
        >
          <FaChevronRight />
        </button>

        <div className={`slide next-slide ${isTransitioning ? 'transitioning next' : ''}`}>
          <img 
            src={images[getNextIndex()]} 
            alt="Следующее блюдо"
            loading="lazy"
          />
        </div>
      </div>

      <Link to="/menu" className="button-base button-primary">
        Смотреть меню
      </Link>
    </div>
  );
};

export default DishCarousel;
