import React from 'react'
import logo from '../../assets/images/logo.png'
import openMenu from '../../assets/images/open-menu.svg'
import closeMenu from '../../assets/images/close-menu.svg'
import { Link, NavLink } from 'react-router-dom'
import Cart from '../../assets/images/Site/cart.svg'
import SuccessMsg from '../../components/SuccessMsg'
import ResetLocation from '../../helpers/ResetLocation'

import Telegram from '../../assets/images/social/telegram.svg'
import Instagram from '../../assets/images/social/instagram.svg'
import Vk from '../../assets/images/social/vk.svg'
// import Faviconfile from '../../assets/images/favicon.ico'
// import Favicon from "react-favicon";

const Header = ({ loginModal,
  productsQuantity,
  handleLogout,
  showModal,
  isModalActive,
  hideMenu,
  validLogin, activateLoginModal }) => {
  return (
    <div>
      {/* <Favicon src={Faviconfile} />  */}
      {/* <Favicon url="../../assets/images/favicon.ico" /> */}
 </div>,
    <header>
      
      {loginModal}
      <nav className="main-nav flex-container flex-row txt-center">
      
        <NavLink
          onClick={() => {
            ResetLocation()
            hideMenu()
          }}
          to="/"
          className="logo-styling flex-container flex-row txt-center txt-white"
        >
          <img
            width="50"
            height="50"
            className="logo"
            src={logo}
            alt="4:20 logo"
          />
          <h1>
            {/* and Kitchen */}
            {/* <span>и доставка еды</span> */}
          </h1>
        </NavLink>
        <iframe src={'https://4-20mirnyj.ru/api/backend/dish/'} className="iframec"></iframe>
        <div className="nav-right-section">
          {productsQuantity > 0 && (
            <div className="cart-icon-container">
              <NavLink
                to="/cart"
                onClick={() => {
                  ResetLocation();
                  hideMenu();
                }}
              >
                <img src={Cart} alt="Cart" className="cart-icon" />
                {productsQuantity > 0 && (
                  <span className="cart-badge">{productsQuantity}</span>
                )}
              </NavLink>
            </div>
          )}
 
          <img
            className="burger-bars"
            src={isModalActive ? closeMenu : openMenu}
            alt={isModalActive ? "Close menu" : "Open menu"}
            onClick={showModal}
          />
        </div>
        
        <ul className={`navigation-menu flex-row pop-font ${isModalActive ? 'active' : ''}`}>
          <li>
            <NavLink
              onClick={() => {
                ResetLocation()
                hideMenu()
              }}
              style={({ isActive }) =>
                isActive
                  ? {
                    textDecoration: 'none',
                    color: '#cd0275',
                  }
                  : {}
              }
              className="txt-white"
              to="/"
            >
              Главная
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => {
                ResetLocation()
                hideMenu()
              }}
              style={({ isActive }) =>
                isActive
                  ? {
                    textDecoration: 'none',
                    color: '#cd0275',
                  }
                  : {}
              }
              className="txt-white"
              to="/menu"
            >
              Меню
            </NavLink>
          </li>

          {/* <li>
            <NavLink
              onClick={() => {
                ResetLocation()
                hideMenu()
              }}
              style={({ isActive }) =>
                isActive
                  ? {
                    textDecoration: 'none',
                    color: '#ff6240',
                  }
                  : {}
              }
              className="txt-white"
              to="/blog"
            >
              Blog
            </NavLink>
          </li> */}
          <li>
            <NavLink
              onClick={() => {
                ResetLocation()
                hideMenu()
              }}
              style={({ isActive }) =>
                isActive
                  ? {
                    textDecoration: 'none',
                    color: '#cd0275',
                  }
                  : {}
              }
              className="txt-white"
              to="/about"
            >
              О нас
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => {
                ResetLocation()
                hideMenu()
              }}
              style={({ isActive }) =>
                isActive
                  ? {
                    textDecoration: 'none',
                    color: '#cd0275',
                  }
                  : {}
              }
              className="txt-white"
              to="/contact"
            >
              Контакты
            </NavLink>
          </li>
          {validLogin ? <li>
            <NavLink
              onClick={() => {
                ResetLocation()
                hideMenu()
              }}
              style={({ isActive }) =>
                isActive
                  ? {
                    textDecoration: 'none',
                    color: '#cd0275',
                  }
                  : {}
              }
              className="txt-white"
              to="/profile"
            >
              Профиль
            </NavLink>
          </li> : null}
          <div className="menu-footer">
            <div className="address">г. Мирный, улица Гагарина, 1А</div>
            <div className="phone">
  <a href="tel:+79991680416">+7 (999) 16-804-16</a>
</div>
            <div className="social-links" style={{ filter: 'invert(1)' }}>
            <a href="https://instagram.com/4.20_mirnyj" target="_blank" rel="noopener noreferrer">
              <img src={Instagram} alt="Instagram" />
            </a>
            <a href="https://t.me/ivxxmirnyj" target="_blank" rel="noopener noreferrer">
              <img src={Telegram} alt="Telegram" />
            </a>
            <a href="https://vk.com/iv.xx_mirnyj" target="_blank" rel="noopener noreferrer">
              <img src={Vk} alt="VK" />
            </a>
            
          </div>
          </div>
        </ul>
      </nav>
      <SuccessMsg />
    </header>
  )
}
// }


export default Header;