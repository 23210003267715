import React, { useEffect, useState } from "react";

const CheckoutItem = ({ cartItem }) => {
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  useEffect(() => {
    cartItem.userSelectedAttributes.map((item) => {
      return setSelectedAttributes(item.attributeValue);
    });
  }, [cartItem.userSelectedAttributes])

  return (
    <section className='checkout-item'>
      <img src={cartItem.image} alt={cartItem.name} />
      <section className="checkout-item-info">
        {cartItem.userSelectedAttributes.length === 0 ?
          <h3>{cartItem.name}</h3> :
          <h3>{cartItem.name}  <span>{selectedAttributes}</span></h3>
        }
        <p>Количество: {cartItem.quantity}</p>
        <p>Цена: ₽ {cartItem.price}</p>
      </section>
    </section>
  );
}

export default CheckoutItem;