import React from "react";

const SuccessMsg = () => {
  return (
    <section className="success-msg">
      <p>Товар добавлен в корзину!</p>
    </section>
  );
};

export default SuccessMsg;
