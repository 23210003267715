import React from "react";

const AboutDedication = () => {
  return (
    <article className="about-dedication">
      <section className="content">
        <h2 className="sub-title">преданные</h2>
        <h3>Меняем мир вместе</h3>
        <p>
        Мы считаем, что наш успех обусловлен нашим стремлением предоставлять высококачественную еду и исключительный сервис. В нашем меню представлен широкий выбор вкусных пицц, салатов, сэндвичей, блюд из пасты и многого другого, приготовленных из свежих местных ингредиентов. Мы уделяем большое внимание тому, чтобы каждое блюдо было приготовлено с максимальным вниманием к деталям, чтобы каждый кусочек был таким же вкусным, как и предыдущий.
        </p>
      </section>
      <div className="black-glass"></div>
    </article>
  );
}

export default AboutDedication;
