import React from 'react'
import { motion } from "framer-motion";
import PizzaOne from '../../assets/images/contact-us/image-one-parallax.webp'


const ContactUsLanding = () => {
  return (
    
    <section className="contact-us-landing flex-container flex-row txt-white" >
     <section className='company-details'>
        <div>
        <a href="tel:+79991680416">
            <h2>+7(999)16-80-416</h2>
          </a>
          <a href="tel:+78183452062">
            <h2>+7(81834)5-20-62</h2>
          </a>
          <p>Свяжитесь с нами если у вас есть какие-либо вопросы</p>
        </div>
        <div>
          <h2>улица Гагарина, 1А </h2>
          <p>Архангельская обл., Мирный</p>
        </div>
        <div>
          <h2>Открыты каждый день</h2>
          <p>Пн-Чт 12:00 - 2:00</p>
          <p>Пт    12:00 - 6:00</p>
          <p>Сб    12:00 - 6:00</p>
          <p>Вс    12:00 - 2:00</p>
        </div>
      </section>
      
      <motion.img
        initial={{ opacity: 0, translateX: 701 }}
        whileInView={{ opacity: 0.85, translateX: 550, }}
        // exit={{ opacity: 0 , translateX: 701}}
        transition={{ duration: 1 }}
        src={PizzaOne} alt="" className="company-details-image"
        // src={PizzaOne} alt="" aria-hidden="true" className="company-details-image"
      />
      <motion.img
        initial={{ opacity: 0, translateX: 701 }}
        whileInView={{ opacity: 0.85, translateX: 650, }}
        // exit={{ opacity: 0 , translateX: 701}}
        transition={{ duration: 1 }}
        src={PizzaOne} alt="" className="company-details-image-two"
      />
      <motion.img
        initial={{ opacity: 0, translateX: 701 }}
        whileInView={{ opacity: 0.85, translateX: 750, }}
        // exit={{ opacity: 0 , translateX: 701}}
        transition={{ duration: 1 }}
        src={PizzaOne} alt="" className="company-details-image-three"
      />
     
      
    </section>
  );

}


export default ContactUsLanding;