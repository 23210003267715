import React, { useEffect, useRef } from 'react';
import img1 from '../../assets/images/Site/colaj/1.webp';
import img2 from '../../assets/images/Site/colaj/2.webp';
import img3 from '../../assets/images/Site/colaj/3.webp';
import img4 from '../../assets/images/Site/colaj/4.webp';
import img5 from '../../assets/images/Site/colaj/5.webp';
import img6 from '../../assets/images/Site/colaj/6.webp';
import img7 from '../../assets/images/Site/colaj/7.webp';
import img8 from '../../assets/images/Site/colaj/8.webp';

const ImageCollage = () => {
  const collageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('appear');
          }
        });
      },
      { threshold: 0.1 }
    );

    const cells = collageRef.current.querySelectorAll('.collage-cell');
    cells.forEach((cell) => observer.observe(cell));

    return () => observer.disconnect();
  }, []);

  return (
    <div className="image-collage" ref={collageRef}>
      <div className="collage-grid">
        <div className="collage-cell fade-in">
          <img src={img1} alt="Коллаж 1" className="vertical-img hover-effect" />
          <img src={img2} alt="Коллаж 2" className="horizontal-img hover-effect" />
        </div>
        <div className="collage-cell fade-in">
          <img src={img3} alt="Коллаж 3" className="horizontal-img hover-effect" />
          <img src={img4} alt="Коллаж 4" className="vertical-img hover-effect" />
        </div>
        <div className="collage-cell fade-in">
          <img src={img5} alt="Коллаж 5" className="vertical-img hover-effect" />
          <img src={img6} alt="Коллаж 6" className="horizontal-img hover-effect" />
        </div>
        <div className="collage-cell fade-in">
          <img src={img7} alt="Коллаж 7" className="horizontal-img hover-effect" />
          <img src={img8} alt="Коллаж 8" className="vertical-img hover-effect" />
        </div>
      </div>
    </div>
  );
};

export default ImageCollage;
