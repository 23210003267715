import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { motion } from "framer-motion";

import { Link } from "react-router-dom";
import ScrollBtn from "../../helpers/ScrollBtn";
import ContactUsLanding from "../landing/ContactUsLanding";
import ContactLanding from "../landing/ContactLanding";
//components
import validateForm from "../../components/validateForm";
import ResetLocation from "../../helpers/ResetLocation";

const Contact = () => {
  useEffect(() => {
    document.title = "О Нас | 4:20";
  }, []);
  return (
    <motion.main
      className="about"
      initial={{ opacity: 0, translateX: -300 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: -300 }}
      transition={{ duration: 1 }}>
     
      <section className="contact-us-img"></section>
      <section className="contact-us-content pop-font">
        <section className="contact-us-content-txt">
          <h2 className="">Связаться с нами</h2>
          <p>
          Мы очень ожидаем вашего обращения и готовы ответить на любые ваши вопросы. Пожалуйста, не стесняйтесь обращаться к нам, если вам потребуются дополнительные разъяснения или помощь. Ваши отзывы и вопросы имеют для нас первостепенное значение, и мы здесь, чтобы предоставить вам необходимую поддержку. С нетерпением ждем Вашего отзыва! </p>
        </section>
      </section>
      <motion.div
        className="contact-emails"
        initial={{ opacity: 0, translateX: 300 }}
        whileInView={{ opacity: 1, translateX: 0 }}
        exit={{ opacity: 0, translateX: 300 }}
        transition={{ duration: 1 }}
      >
        <h3>Связаться с нами</h3>
        <p>Есть вопрос, предложение или просто хотите поздороваться? Мы хотели бы услышать вас!</p>
        <p><br></br></p>
        <section>
          <h4>Служба поддержки:</h4>
          <p>Наша специализированная команда поддержки клиентов готова помочь вам с любыми вопросами, которые могут у вас возникнуть.</p>
          <ul>
            <li>- Электронная почта: 4-20hookah@mail.ru</li>
            <li>- Телефон: <a href="tel:+79991680416">+7(999)16-80-416</a> , <a href="tel:+78183452062">+7(81834)5-20-62</a></li>

            <li>- Часы работы: </li>
            <li>Пн-Чт 12:00 - 2:00</li>
            <li>Пт    12:00 - 6:00</li>
            <li>Сб    12:00 - 6:00</li>
            <li>Вс    12:00 - 2:00</li>
          </ul>
          <p><br></br></p>
        </section>
        <section>
          <h4>Общие вопросы:</h4>
          <p>Если у вас есть общие вопросы о нашей компании, партнерстве или чем-то еще, свяжитесь с нами.</p>
          <ul>
            <li>- Электронная почта: 4-20hookah@mail.ru</li>
          </ul>
          <p><br></br></p>
        </section>
        <section>
          <h4>Отзывы и предложения:</h4>
          <p>Мы ценим ваши отзывы и всегда стремимся совершенствоваться. Если у вас есть какие-либо предложения, комментарии или идеи о том, как мы можем улучшить наши предложения, сообщите нам об этом.</p>
          <ul>
            <li>- Электронная почта: 4-20hookah@mail.ru</li>
          </ul>
        </section>
      </motion.div>
      <ContactUsLanding />
      <ContactLanding />
      
      <ScrollBtn />
    </motion.main>
  );
}
    


export default Contact;