import React from "react";
import MenuCategories from "./MenuCategories";
import ScrollButton from "../../helpers/ScrollBtn";
import MenuGridItem from "./MenuGridItem";
import ReactPaginate from 'react-paginate';
import { useState, useEffect } from "react";
import ResetLocation from "../../helpers/ResetLocation";
import { motion } from "framer-motion";

const Menu =  ({ allProducts,
  activeCategory,
  allCategories,
  changeCategory,
  handleAddProduct,
  handleRemoveProduct,
  findMenuItem
}) => {

  const [itemOffset, setItemOffset] = useState(0);
  const [endOffset, setEndOffset] = useState(itemOffset + 999);
  const [currentProducts, setcurrentProducts] = useState(allProducts.slice(itemOffset, endOffset));
  const [pageCountProducts, setpageCountProducts] = useState(Math.ceil(allProducts.length / 999));

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 999) % allProducts.length;
    setItemOffset(newOffset);
    ResetLocation();
  };

  const resetPagination = () => {
    setItemOffset(0);
    setEndOffset(6);
  };

  useEffect(() => {
    document.title = `${activeCategory} | 4:20`;
  
    // Вычисляем endOffset локально
    const calculatedEndOffset = itemOffset + 999;
  
    // Сортируем продукты по алфавиту
    const sortedProducts = [...allProducts].sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    // Удаляем цифры и пробелы в начале названия
    const productsWithoutPrefix = sortedProducts.map((product) => ({
      ...product,
      name: product.name.replace(/^\d+/, ''),
    }));
  
    // Устанавливаем продукты для текущей страницы
    console.log("allProducts", allProducts);
    console.log("sortedProducts", sortedProducts);
    console.log("productsWithoutPrefix", productsWithoutPrefix);
    // Используем вычисленное значение endOffset
    setcurrentProducts(productsWithoutPrefix.slice(itemOffset, calculatedEndOffset));
    setpageCountProducts(Math.ceil(productsWithoutPrefix.length / 999));
  }, [allProducts, itemOffset, activeCategory, allCategories]);

  return (
       <motion.main
      className="menu-main"
      initial={{ opacity: 0, translateX: -300 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      exit={{ opacity: 0, translateX: -300 }}
      transition={{ duration: 1 }}
    >
      <MenuCategories
        activeCategory={activeCategory}
        allCategories={allCategories}
        changeCategory={changeCategory}
        resetPagination={resetPagination}
        findMenuItem={findMenuItem}
      />
      {currentProducts.length === 0 ?
        <article className="menu-grid">
          <p className="nothing-found">Ничего не найдено... повторите ввод или перезагрузите страницу</p>
        </article> :
        <article className="menu-grid">
          {currentProducts.map((singleProduct) => (
            <MenuGridItem
              key={singleProduct.id}
              singleProduct={singleProduct}
              handleAddProduct={handleAddProduct}
              handleRemoveProduct={handleRemoveProduct}
            />
          ))
          }
          <ScrollButton />
        </article>}

      <ReactPaginate
        className="pagination"
        breakLabel="..."
        nextLabel=" &#62;"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCountProducts}
        previousLabel="&#60;"
        renderOnZeroPageCount={null}
      />
    </motion.main>
  );
}

export default Menu;
