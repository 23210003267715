import { Link } from "react-router-dom";
import React from 'react'
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IoArrowForward } from "react-icons/io5";


import "react-alice-carousel/lib/alice-carousel.css";
/*import HeroVideo from '../../assets/images/hero/hero-bg.mp4';*/


import img_l from '../../assets/images/Site/Hero_L_t.webp'
import img_l_1400 from '../../assets/images/Site/Hero_L_t-1400.webp'
import img_l_700 from '../../assets/images/Site/Hero_L_t-700.webp'

import svg420 from '../../assets/images/Site/420.svg'; // Импортируем SVG файл

const Hero = () => {
  return (
    <section className="hero-section">
      <section className="hero-section_i">
        <LazyLoadImage
          className="hero-img"
          src={img_l_700} // меняем дефолтный src на наименьшее изображение
          srcSet={`${img_l} 2800w, ${img_l_1400} 1400w, ${img_l_700} 700w`}
          sizes="(min-width: 1400px) 2800px, (min-width: 700px) 1400px, 700px"
          alt="4:20 интерьер ресторана"
        />
      </section>

      <section className="header-info flex-container flex-column txt-center pop-font">
      <motion.div
          initial={{ opacity: 0, translateY: 50 }}
          whileInView={{ opacity: 1, translateY: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1.5 }}
        >
           <img src={svg420} alt="ЧЕТЫРЕ ДВАДЦАТЬ" style={{ width: '40rem', maxWidth: '90vw' }}/>
           <p className="subtitle">еда бар магазин и музыка</p>
        </motion.div>

        <motion.div 
          className="header-btns"
          initial={{ opacity: 0, translateY: 30 }}
          whileInView={{ opacity: 1, translateY: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1.5, delay: 0.5 }}
        >
          <Link className="button-base button-secondary" to="/menu">
            Заказать еду
            <IoArrowForward className="button-arrow" />
          </Link>
        </motion.div>
      </section>
    </section>
  );
}
 
export default Hero;