import React from "react";
import Github from "../../assets/images/socials/VK Logo.svg";
import instagram from "../../assets/images/socials/instagram-11.svg";
import dprofile from "../../assets/images/socials/behance.svg";
import telegram from "../../assets/images/socials/telegram.svg";

const socials = [
  {
    id: 1,
    href: "https://vk.com/iv.xx_mirnyj",
    img: Github,
    name: Github
  },
  {
    id: 2,
    href: "https://instagram.com/4.20_mirnyj",
    img: instagram,
    name: instagram
  },
  {
    id: 3,
    href: "https://t.me/ivxxmirnyj",
    img: telegram,
    name: telegram
  },
  {
    id: 4,
    href: "https://dprofile.ru/arrancore",
    img: dprofile,
    name: dprofile
  }
];

export default class FooterSocials extends React.Component {
  render() {
    return (
      <ul className="socials">
      {socials.map(social =>
      <li key={social.id}>
          <a
            href={social.href}
            target="_blank"
            rel="noreferrer"
            aria-label={social.name}
          >
          <span aria-hidden="true">{social.name}</span>
            <img src={social.img} alt="" aria-hidden="true" />
          </a>
        </li>)}
      </ul>
    );
  }
}
