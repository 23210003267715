import React, { useState } from 'react';
import styled from 'styled-components';
import gal1 from '../../assets/images/Site/gal1/1.webp';
import gal2 from '../../assets/images/Site/gal1/2.webp';
import gal3 from '../../assets/images/Site/gal1/3.webp';
import gal4 from '../../assets/images/Site/gal1/4.webp';
import gal5 from '../../assets/images/Site/gal1/5.webp';
import gal6 from '../../assets/images/Site/gal1/6.webp';

const GalleryContainer = styled.div`
  position: relative;
//   max-width: 1440px;
  margin: 0 auto;
  padding: 4rem 0;
`;

const GalleryWrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
`;

const GalleryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
`;

const Arrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
//   border: none;
border-color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--whitish);
  font-size: 1.5rem;
  z-index: 2;
  transition: all 0.3s ease;

  &:hover {
    background: var(--green);
  }
 
  ${props => props.direction === 'left' ? 'left: 20px;' : 'right: 20px;'}
`;

const Dots = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
  z-index: 2;
`;

const Dot = styled.button`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid var(--whitish);
  background: ${props => props.$active ? 'var(--whitish)' : 'transparent'};
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0;

  &:hover {
    background: var(--green);
    border-color: var(--green);
  }
`;

const ImageGallery = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [gal1, gal2, gal3, gal4, gal5, gal6];

  const nextImage = () => {
    setCurrentImage((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <GalleryContainer>
      <GalleryWrapper>
        <Arrow direction="left" onClick={prevImage}>&lt;</Arrow>
        <GalleryImage src={images[currentImage]} alt={`Gallery image ${currentImage + 1}`} />
        <Arrow direction="right" onClick={nextImage}>&gt;</Arrow>
        <Dots>
          {images.map((_, index) => (
            <Dot 
              key={index} 
              $active={currentImage === index}
              onClick={() => setCurrentImage(index)} 
            />
          ))}
        </Dots>
      </GalleryWrapper>
    </GalleryContainer>
  );
};

export default ImageGallery; 