
export const  allProductsData1 = [
    {
        "id": 3,
        "name": "Фруктовая тарелка",
        "price": 420,
        "image": "https://4-20mirnyj.ru/media/image/M_1.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_1.webp",
        "category_name": "Холодные закуски",
        "description": "Груша, яблоко, апельсин, вишня консервированная",
        "status": "available",
        "calories": "60.0",
        "weight": "340.0",
        "proteins": "2.0",
        "fats": "1.0",
        "carbohydrates": "30.0"
    },
    {
        "id": 4,
        "name": "Салат с креветками и авокадо",
        "price": 590,
        "image": "https://4-20mirnyj.ru/media/image/M_51.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_51.webp",
        "category_name": "1Салаты",
        "description": "Айсберг, руккола, лук красный, авокадо, моцарелла, креветки, семечки тыквенные, фирменная заправка",
        "status": "available",
        "calories": "150.0",
        "weight": "290.0",
        "proteins": "16.0",
        "fats": "14.0",
        "carbohydrates": "10.0"
    },
    {
        "id": 6,
        "name": "Салат тайский с ростбифом",
        "price": 590,
        "image": "https://4-20mirnyj.ru/media/image/webb.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/swebb.webp",
        "category_name": "1Салаты",
        "description": "ростбиф, перец болгарский, томаты, огурец, руккола, лук красный, кунжут, лук зеленый, фирм. заправка",
        "status": "available",
        "calories": "220.0",
        "weight": "290.0",
        "proteins": "20.0",
        "fats": "10.0",
        "carbohydrates": "8.0"
    },
    {
        "id": 7,
        "name": "Салат Цезарь с курицей",
        "price": 490,
        "image": "https://4-20mirnyj.ru/media/image/M_35.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_35.webp",
        "category_name": "1Салаты",
        "description": "Черри, айсберг, гренки, пармезан, яйцо перепелиное, соус цезарь, курица",
        "status": "available",
        "calories": "230.0",
        "weight": "270.0",
        "proteins": "18.0",
        "fats": "12.0",
        "carbohydrates": "14.0"
    },
    {
        "id": 8,
        "name": "Салат Цезарь с лососем",
        "price": 590,
        "image": "https://4-20mirnyj.ru/media/image/M_39.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_39.webp",
        "category_name": "1Салаты",
        "description": "Черри, айсберг, гренки, пармезан, яйцо перепелиное, соус цезарь, лосось слабосолёный",
        "status": "available",
        "calories": "240.0",
        "weight": "240.0",
        "proteins": "20.0",
        "fats": "15.0",
        "carbohydrates": "10.0"
    },
    {
        "id": 9,
        "name": "Салат Цезарь с креветками",
        "price": 560,
        "image": "https://4-20mirnyj.ru/media/image/%D1%86%D0%B5%D0%B7_%D1%81_%D0%BA%D1%80.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/%D0%BC_%D1%86%D0%B5%D0%B7_%D1%81_%D0%BA%D1%80.webp",
        "category_name": "1Салаты",
        "description": "Черри, айсберг, гренки, пармезан, яйцо перепелиное, соус цезарь, креветки",
        "status": "available",
        "calories": "220.0",
        "weight": "270.0",
        "proteins": "19.0",
        "fats": "13.0",
        "carbohydrates": "9.0"
    },
    {
        "id": 10,
        "name": "Салат овощной с сыром фета",
        "price": 390,
        "image": "https://4-20mirnyj.ru/media/image/M_40.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_40.webp",
        "category_name": "1Салаты",
        "description": "Томаты, огурец, болгарский перец, фета, лук красный, руккола, маслины",
        "status": "available",
        "calories": "180.0",
        "weight": "270.0",
        "proteins": "10.0",
        "fats": "8.0",
        "carbohydrates": "12.0"
    },
    {
        "id": 12,
        "name": "Мясное ассорти",
        "price": 650,
        "image": "https://4-20mirnyj.ru/media/image/%D0%BC%D1%8F%D1%81%D0%BD.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/%D0%BC_%D0%BC%D1%8F%D1%81%D0%BD.webp",
        "category_name": "Холодные закуски",
        "description": "Ростбиф, колбаса сырокопчёная, ветчина, соус сальса",
        "status": "available",
        "calories": "280.0",
        "weight": "220.0",
        "proteins": "20.0",
        "fats": "22.0",
        "carbohydrates": "5.0"
    },
    {
        "id": 13,
        "name": "Сырное плато",
        "price": 690,
        "image": "https://4-20mirnyj.ru/media/image/M_8.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_8.webp",
        "category_name": "Холодные закуски",
        "description": "Пармезан, фета, моцарелла, дорблю, гауда, орех грецкий, мёд, семечки тыквенные",
        "status": "available",
        "calories": "350.0",
        "weight": "230.0",
        "proteins": "18.0",
        "fats": "28.0",
        "carbohydrates": "8.0"
    },
    {
        "id": 14,
        "name": "1Кольца кальмара",
        "price": 340,
        "image": "https://4-20mirnyj.ru/media/image/M_10.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_10.webp",
        "category_name": "Горячие закуски",
        "description": "Кальмар, соус тартар",
        "status": "available",
        "calories": "200.0",
        "weight": "200.0",
        "proteins": "15.0",
        "fats": "8.0",
        "carbohydrates": "5.0"
    },
    {
        "id": 15,
        "name": "Мидии в створках спайси",
        "price": 450,
        "image": "https://4-20mirnyj.ru/media/image/%D0%BC%D0%B8%D0%B4%D0%B8%D0%B8.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/%D0%BC_%D0%BC%D0%B8%D0%B4%D0%B8%D0%B8.webp",
        "category_name": "Горячие закуски",
        "description": "Мидии, чеснок, кинза, сливки, томатный соус",
        "status": "notavailable",
        "calories": "150.0",
        "weight": "230.0",
        "proteins": "18.0",
        "fats": "4.0",
        "carbohydrates": "3.0"
    },
    {
        "id": 16,
        "name": "Куриные наггетсы",
        "price": 290,
        "image": "https://4-20mirnyj.ru/media/image/%D0%BD%D0%B0%D0%B3.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/%D0%BC_%D0%BD%D0%B0%D0%B3.webp",
        "category_name": "Горячие закуски",
        "description": "_",
        "status": "available",
        "calories": "300.0",
        "weight": "150.0",
        "proteins": "15.0",
        "fats": "12.0",
        "carbohydrates": "20.0"
    },
    {
        "id": 17,
        "name": "Шашлычки из курицы в беконе",
        "price": 470,
        "image": "https://4-20mirnyj.ru/media/image/M_43.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_43.webp",
        "category_name": "Горячие закуски",
        "description": "Курица, черри, бекон, соус тартар",
        "status": "available",
        "calories": "250.0",
        "weight": "240.0",
        "proteins": "22.0",
        "fats": "15.0",
        "carbohydrates": "5.0"
    },
    {
        "id": 18,
        "name": "Куриные крылья барбекю 🔥",
        "price": 390,
        "image": "https://4-20mirnyj.ru/media/image/M_58_dmF0cKm.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_58_dX9XqdE.webp",
        "category_name": "Горячие закуски",
        "description": "_",
        "status": "available",
        "calories": "220.0",
        "weight": "240.0",
        "proteins": "24.0",
        "fats": "13.0",
        "carbohydrates": "3.0"
    },
    {
        "id": 19,
        "name": "Гренки с сыром",
        "price": 250,
        "image": "https://4-20mirnyj.ru/media/image/M_11.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_11.webp",
        "category_name": "Горячие закуски",
        "description": "Хлеб бородинский, соус чесночный, пармезан",
        "status": "available",
        "calories": "250.0",
        "weight": "230.0",
        "proteins": "8.0",
        "fats": "10.0",
        "carbohydrates": "30.0"
    },
    {
        "id": 20,
        "name": "Картофель фри",
        "price": 170,
        "image": "https://4-20mirnyj.ru/media/image/%D0%A4%D1%80%D0%B8.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/%D0%BC_%D0%A4%D1%80%D0%B8.webp",
        "category_name": "Горячие закуски",
        "description": "_",
        "status": "available",
        "calories": "310.0",
        "weight": "150.0",
        "proteins": "3.0",
        "fats": "15.0",
        "carbohydrates": "30.0"
    },
    {
        "id": 21,
        "name": "Картофель Айдахо",
        "price": 190,
        "image": "https://4-20mirnyj.ru/media/image/%D0%90%D0%B9%D0%B4%D0%B0%D1%85%D0%BE.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/%D0%BC_%D0%90%D0%B9%D0%B4%D0%B0%D1%85%D0%BE.webp",
        "category_name": "Горячие закуски",
        "description": "_",
        "status": "available",
        "calories": "250.0",
        "weight": "150.0",
        "proteins": "2.0",
        "fats": "12.0",
        "carbohydrates": "25.0"
    },
    {
        "id": 22,
        "name": "Рамен с курицей 🔥",
        "price": 480,
        "image": "https://4-20mirnyj.ru/media/image/%D1%80%D0%B0%D0%BC%D0%B5%D0%BD.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/%D0%BC_%D1%80%D0%B0%D0%BC%D0%B5%D0%BD.webp",
        "category_name": "Супы",
        "description": "Курица, лапша яичная, яйцо, соевый соус, лук зеленый, чеснок, чили, кунжут",
        "status": "available",
        "calories": "250.0",
        "weight": "570.0",
        "proteins": "18.0",
        "fats": "12.0",
        "carbohydrates": "40.0"
    },
    {
        "id": 23,
        "name": "2 Том ям 🔥",
        "price": 640,
        "image": "https://4-20mirnyj.ru/media/image/%D1%82%D0%BE%D0%BC_%D1%8F%D0%BC.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/%D0%BC_%D1%82%D0%BE%D0%BC_%D1%8F%D0%BC.webp",
        "category_name": "Супы",
        "description": "Креветки, лосось, шампиньоны, черри, молоко кокосовое, сливки, чили, кинза, рис",
        "status": "available",
        "calories": "100.0",
        "weight": "480.0",
        "proteins": "15.0",
        "fats": "22.0",
        "carbohydrates": "30.0"
    },
    {
        "id": 24,
        "name": "Крем-суп из белых грибов",
        "price": 490,
        "image": "https://4-20mirnyj.ru/media/image/%D0%BA%D1%80%D0%B5%D0%BC.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/%D0%BC_%D0%BA%D1%80%D0%B5%D0%BC.webp",
        "category_name": "Супы",
        "description": "Белые грибы, шампиньоны, лук, картофель, сливки, гренки",
        "status": "available",
        "calories": "120.0",
        "weight": "430.0",
        "proteins": "6.0",
        "fats": "18.0",
        "carbohydrates": "35.0"
    },
    {
        "id": 25,
        "name": "Томатный суп с копчёностями",
        "price": 380,
        "image": "https://4-20mirnyj.ru/media/image/%D1%82%D0%BE%D0%BC%D0%B0%D1%82.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/%D0%BC_%D1%82%D0%BE%D0%BC%D0%B0%D1%82.webp",
        "category_name": "Супы",
        "description": "колбаски охотничьи, бекон, томаты",
        "status": "available",
        "calories": "150.0",
        "weight": "420.0",
        "proteins": "6.0",
        "fats": "10.0",
        "carbohydrates": "15.0"
    },
    {
        "id": 26,
        "name": "Фокачча",
        "price": 120,
        "image": "https://4-20mirnyj.ru/media/image/M_54.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_54.webp",
        "category_name": "Хлеб",
        "description": "Мука пшеничная, чеснок, соус песто",
        "status": "available",
        "calories": "250.0",
        "weight": "250.0",
        "proteins": "8.0",
        "fats": "5.0",
        "carbohydrates": "40.0"
    },
    {
        "id": 27,
        "name": "Хлебная корзина",
        "price": 150,
        "image": "https://4-20mirnyj.ru/media/image/M_s_13.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_13.webp",
        "category_name": "Хлеб",
        "description": "Булочка баварская (2 шт.), булочка французская (2 шт.), масло зелёное",
        "status": "available",
        "calories": "270.0",
        "weight": "210.0",
        "proteins": "7.0",
        "fats": "8.0",
        "carbohydrates": "45.0"
    },
    {
        "id": 28,
        "name": "Спагетти Карбонара",
        "price": 450,
        "image": "https://4-20mirnyj.ru/media/image/%D0%BA%D0%B0%D1%80%D0%B1.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/%D0%BC_%D0%BA%D0%B0%D1%80%D0%B1.webp",
        "category_name": "Паста",
        "description": "Спагетти, желток, бекон, пармезан, сливки",
        "status": "available",
        "calories": "400.0",
        "weight": "250.0",
        "proteins": "10.0",
        "fats": "25.0",
        "carbohydrates": "30.0"
    },
    {
        "id": 29,
        "name": "Триколлини с курицей и томатами",
        "price": 410,
        "image": "https://4-20mirnyj.ru/media/image/%D1%82%D1%80%D0%B8%D0%BA.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/%D0%BC_%D1%82%D1%80%D0%B8%D0%BA.webp",
        "category_name": "Паста",
        "description": "триколлини, курица, черри, томатный соус",
        "status": "available",
        "calories": "350.0",
        "weight": "280.0",
        "proteins": "15.0",
        "fats": "12.0",
        "carbohydrates": "25.0"
    },
    {
        "id": 30,
        "name": "Фунгетти с вырезкой из говядины и грибами",
        "price": 530,
        "image": "https://4-20mirnyj.ru/media/image/M_55.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_55.webp",
        "category_name": "Паста",
        "description": "Фунгетти, говядина, лук, шампиньоны, сливки, сметана, пармезан, масло трюфельное",
        "status": "available",
        "calories": "320.0",
        "weight": "300.0",
        "proteins": "20.0",
        "fats": "28.0",
        "carbohydrates": "22.0"
    },
    {
        "id": 31,
        "name": "Удон с морепродуктами",
        "price": 690,
        "image": "https://4-20mirnyj.ru/media/image/M_61.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_61.webp",
        "category_name": "Паста",
        "description": "Удон, креветки, мидии, морской коктейль, болгарский перец, лук, огурец, кунжут, терияки, соевый соус",
        "status": "notavailable",
        "calories": "250.0",
        "weight": "480.0",
        "proteins": "22.0",
        "fats": "18.0",
        "carbohydrates": "25.0"
    },
    {
        "id": 35,
        "name": "Бургер с курицей",
        "price": 510,
        "image": "https://4-20mirnyj.ru/media/image/%D0%B1%D1%83%D1%80%D0%B3.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/%D0%BC_%D0%B1%D1%83%D1%80%D0%B3.webp",
        "category_name": "Бургеры",
        "description": "Курица, чеддер, яйцо, томаты, айсберг, соус тартар и цезарь, кольца кальмара",
        "status": "available",
        "calories": "350.0",
        "weight": "430.0",
        "proteins": "30.0",
        "fats": "15.0",
        "carbohydrates": "25.0"
    },
    {
        "id": 36,
        "name": "Чизбургер с беконом",
        "price": 630,
        "image": "https://4-20mirnyj.ru/media/image/%D1%87%D0%B8%D0%B7%D0%B1.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/%D0%BC_%D1%87%D0%B8%D0%B7%D0%B1.webp",
        "category_name": "Бургеры",
        "description": "Говядина, бекон, горчица, чеддер, томатный и сырный соус, лук красный, огурец маринованный",
        "status": "available",
        "calories": "400.0",
        "weight": "390.0",
        "proteins": "30.0",
        "fats": "25.0",
        "carbohydrates": "20.0"
    },
    {
        "id": 38,
        "name": "Биточки из птицы с жульеном",
        "price": 470,
        "image": "https://4-20mirnyj.ru/media/image/%D0%B1%D0%B8%D1%82.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/%D0%BC_%D0%B1%D0%B8%D1%82.webp",
        "category_name": "Горячие блюда",
        "description": "Курица, картофель, лук, шампиньоны, сливки, сметана, чеснок, зелень",
        "status": "available",
        "calories": "250.0",
        "weight": "290.0",
        "proteins": "20.0",
        "fats": "18.0",
        "carbohydrates": "25.0"
    },
    {
        "id": 41,
        "name": "Стейк из свинины",
        "price": 540,
        "image": "https://4-20mirnyj.ru/media/image/M_16.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_16.webp",
        "category_name": "Горячие блюда",
        "description": "Свиная шея, руккола, черри, фирменная заправка",
        "status": "available",
        "calories": "250.0",
        "weight": "190.0",
        "proteins": "30.0",
        "fats": "20.0",
        "carbohydrates": "10.0"
    },
    {
        "id": 46,
        "name": "Блины с курицей и грибами",
        "price": 360,
        "image": "https://4-20mirnyj.ru/media/image/%D0%BA%D1%83%D1%80.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/%D0%BC_%D0%BA%D1%83%D1%80.webp",
        "category_name": "Блины",
        "description": "Курица, шампиньоны, сливки",
        "status": "available",
        "calories": "280.0",
        "weight": "220.0",
        "proteins": "14.0",
        "fats": "18.0",
        "carbohydrates": "28.0"
    },
    {
        "id": 47,
        "name": "Блины с яблоком и корицей",
        "price": 340,
        "image": "https://4-20mirnyj.ru/media/image/%D0%BA%D0%BE%D1%80%D0%B8%D1%86.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/%D0%BC_%D0%BA%D0%BE%D1%80%D0%B8%D1%86.webp",
        "category_name": "Блины",
        "description": "Яблоко, корица, орех грецкий, мёд",
        "status": "available",
        "calories": "210.0",
        "weight": "250.0",
        "proteins": "4.0",
        "fats": "8.0",
        "carbohydrates": "40.0"
    },
    {
        "id": 49,
        "name": "Блины со сливочным маслом",
        "price": 210,
        "image": "https://4-20mirnyj.ru/media/image/M_32.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_32.webp",
        "category_name": "Блины",
        "description": "_",
        "status": "available",
        "calories": "300.0",
        "weight": "170.0",
        "proteins": "5.0",
        "fats": "22.0",
        "carbohydrates": "30.0"
    },
    {
        "id": 50,
        "name": "Пицца Маргарита",
        "price": 590,
        "image": "https://4-20mirnyj.ru/media/image/M_20.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_20.webp",
        "category_name": "Пицца",
        "description": "Моцарелла, томаты, соус песто (30 см)",
        "status": "available",
        "calories": "250.0",
        "weight": "530.0",
        "proteins": "12.0",
        "fats": "15.0",
        "carbohydrates": "35.0"
    },
    {
        "id": 51,
        "name": "Пицца Четыре сыра",
        "price": 670,
        "image": "https://4-20mirnyj.ru/media/image/M_56.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_56.webp",
        "category_name": "Пицца",
        "description": "Моцарелла, гауда, пармезан, дорблю (30 см)",
        "status": "available",
        "calories": "300.0",
        "weight": "520.0",
        "proteins": "15.0",
        "fats": "25.0",
        "carbohydrates": "30.0"
    },
    {
        "id": 52,
        "name": "Пицца с грушей и горгонзолой",
        "price": 640,
        "image": "https://4-20mirnyj.ru/media/image/M_27.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_27.webp",
        "category_name": "Пицца",
        "description": "Моцарелла, груша, горгонзола, орех грецкий (30 см)",
        "status": "available",
        "calories": "320.0",
        "weight": "590.0",
        "proteins": "14.0",
        "fats": "22.0",
        "carbohydrates": "35.0"
    },
    {
        "id": 53,
        "name": "Пицца с лососем и творожным сыром",
        "price": 890,
        "image": "https://4-20mirnyj.ru/media/image/M_30.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_30.webp",
        "category_name": "Пицца",
        "description": "Моцарелла, лосось слабосоленый, черри, лук зелёный, творожный сыр (30 см)",
        "status": "available",
        "calories": "350.0",
        "weight": "630.0",
        "proteins": "18.0",
        "fats": "22.0",
        "carbohydrates": "30.0"
    },
    {
        "id": 54,
        "name": "Пицца Пепперони",
        "price": 570,
        "image": "https://4-20mirnyj.ru/media/image/M_15.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_15.webp",
        "category_name": "Пицца",
        "description": "Моцарелла, пепперони (30 см)",
        "status": "available",
        "calories": "300.0",
        "weight": "520.0",
        "proteins": "11.0",
        "fats": "14.0",
        "carbohydrates": "30.0"
    },
    {
        "id": 55,
        "name": "Пицца Курица терияки",
        "price": 590,
        "image": "https://4-20mirnyj.ru/media/image/M_21.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_21.webp",
        "category_name": "Пицца",
        "description": "Моцарелла, курица, томаты, соус терияки, кунжут, лук (30 см)",
        "status": "available",
        "calories": "280.0",
        "weight": "620.0",
        "proteins": "12.0",
        "fats": "15.0",
        "carbohydrates": "25.0"
    },
    {
        "id": 56,
        "name": "Сэндвич с копчёным лососем",
        "price": 610,
        "image": "https://4-20mirnyj.ru/media/image/M_18.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_18.webp",
        "category_name": "Сэндвичи",
        "description": "Тостовый хлеб, лосось холодного копчения, творожный сыр, руккола, огурец, айсберг",
        "status": "available",
        "calories": "250.0",
        "weight": "260.0",
        "proteins": "18.0",
        "fats": "16.0",
        "carbohydrates": "12.0"
    },
    {
        "id": 57,
        "name": "Сэндвич с ветчиной и беконом",
        "price": 390,
        "image": "https://4-20mirnyj.ru/media/image/M_17.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_17.webp",
        "category_name": "Сэндвичи",
        "description": "Тостовый хлеб, ветчина, яйцо, бекон, огурец маринованный, соус чесночный, айсберг",
        "status": "available",
        "calories": "300.0",
        "weight": "280.0",
        "proteins": "20.0",
        "fats": "22.0",
        "carbohydrates": "8.0"
    },
    {
        "id": 58,
        "name": "Сэндвич с курицей и сыром",
        "price": 450,
        "image": "https://4-20mirnyj.ru/media/image/M_9.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/M_s_9.webp",
        "category_name": "Сэндвичи",
        "description": "Тостовый хлеб, курица, плавленый сыр, томаты, тартар, соус сырный, айсберг",
        "status": "available",
        "calories": "270.0",
        "weight": "290.0",
        "proteins": "18.0",
        "fats": "15.0",
        "carbohydrates": "20.0"
    },
    {
        "id": 65,
        "name": "Соус Тартар",
        "price": 45,
        "image": "https://4-20mirnyj.ru/media/image/139144211-sous-tsezar-600x600.png",
        "image_s": "https://4-20mirnyj.ru/media/image_s/139144211-sous-tsezar-600x600.png",
        "category_name": "Соусы",
        "description": "_",
        "status": "available",
        "calories": "150.0",
        "weight": "30.0",
        "proteins": "2.0",
        "fats": "11.0",
        "carbohydrates": "2.0"
    },
    {
        "id": 66,
        "name": "Соус Чесночный",
        "price": 45,
        "image": "https://4-20mirnyj.ru/media/image/244442810-sous-chesnochnyj-600x600_1.png",
        "image_s": "https://4-20mirnyj.ru/media/image_s/244442810-sous-chesnochnyj-600x600_1.png",
        "category_name": "Соусы",
        "description": "_",
        "status": "available",
        "calories": "120.0",
        "weight": "30.0",
        "proteins": "1.0",
        "fats": "8.0",
        "carbohydrates": "2.0"
    },
    {
        "id": 67,
        "name": "Соус Барбекю",
        "price": 45,
        "image": "https://4-20mirnyj.ru/media/image/553479336-sous-barbekyu-600x600.png",
        "image_s": "https://4-20mirnyj.ru/media/image_s/553479336-sous-barbekyu-600x600.png",
        "category_name": "Соусы",
        "description": "_",
        "status": "available",
        "calories": "100.0",
        "weight": "30.0",
        "proteins": "1.0",
        "fats": "7.0",
        "carbohydrates": "9.0"
    },
    {
        "id": 68,
        "name": "Соус Сырный",
        "price": 45,
        "image": "https://4-20mirnyj.ru/media/image/270582398-sous-syrnyj-600x600.png",
        "image_s": "https://4-20mirnyj.ru/media/image_s/270582398-sous-syrnyj-600x600.png",
        "category_name": "Соусы",
        "description": "_",
        "status": "available",
        "calories": "200.0",
        "weight": "30.0",
        "proteins": "6.0",
        "fats": "10.0",
        "carbohydrates": "2.0"
    },
    {
        "id": 69,
        "name": "Соус Сальса",
        "price": 45,
        "image": "https://4-20mirnyj.ru/media/image/872029140-sous-sladkij-chili-600x600.png",
        "image_s": "https://4-20mirnyj.ru/media/image_s/872029140-sous-sladkij-chili-600x600.png",
        "category_name": "Соусы",
        "description": "_",
        "status": "available",
        "calories": "50.0",
        "weight": "30.0",
        "proteins": "1.0",
        "fats": "0.5",
        "carbohydrates": "10.0"
    },
    {
        "id": 70,
        "name": "Соус Томатный",
        "price": 45,
        "image": "https://4-20mirnyj.ru/media/image/491737064-ketchup-600x600.png",
        "image_s": "https://4-20mirnyj.ru/media/image_s/491737064-ketchup-600x600.png",
        "category_name": "Соусы",
        "description": "_",
        "status": "available",
        "calories": "40.0",
        "weight": "30.0",
        "proteins": "1.0",
        "fats": "0.2",
        "carbohydrates": "9.0"
    },
    {
        "id": 71,
        "name": "Мёд",
        "price": 45,
        "image": "https://4-20mirnyj.ru/media/image/221995630-olivkovoe-maslo-600x600.png",
        "image_s": "https://4-20mirnyj.ru/media/image_s/221995630-olivkovoe-maslo-600x600.png",
        "category_name": "Топпинги",
        "description": "_",
        "status": "available",
        "calories": "300.0",
        "weight": "30.0",
        "proteins": "0.3",
        "fats": "0.0",
        "carbohydrates": "40.0"
    },
    {
        "id": 72,
        "name": "Сгущённое молоко",
        "price": 45,
        "image": "https://4-20mirnyj.ru/media/image/244442810-sous-chesnochnyj-600x600_iBYBotY.png",
        "image_s": "https://4-20mirnyj.ru/media/image_s/244442810-sous-chesnochnyj-600x600_1fHEBdP.png",
        "category_name": "Топпинги",
        "description": "_",
        "status": "available",
        "calories": "320.0",
        "weight": "30.0",
        "proteins": "7.0",
        "fats": "8.0",
        "carbohydrates": "61.0"
    },
    {
        "id": 73,
        "name": "Клубничный джем",
        "price": 45,
        "image": "https://4-20mirnyj.ru/media/image/729923026-klubnichnyj-dgem-600x600_J65tTRm.png",
        "image_s": "https://4-20mirnyj.ru/media/image_s/729923026-klubnichnyj-dgem-600x600_XuDWP8x.png",
        "category_name": "Топпинги",
        "description": "_",
        "status": "available",
        "calories": "250.0",
        "weight": "30.0",
        "proteins": "0.5",
        "fats": "0.1",
        "carbohydrates": "13.0"
    },
    {
        "id": 74,
        "name": "Вишнёвый джем",
        "price": 45,
        "image": "https://4-20mirnyj.ru/media/image/729923026-klubnichnyj-dgem-600x600.png",
        "image_s": "https://4-20mirnyj.ru/media/image_s/729923026-klubnichnyj-dgem-600x600.png",
        "category_name": "Топпинги",
        "description": "_",
        "status": "available",
        "calories": "250.0",
        "weight": "30.0",
        "proteins": "0.5",
        "fats": "0.1",
        "carbohydrates": "14.0"
    },
    {
        "id": 75,
        "name": "Сметана",
        "price": 45,
        "image": "https://4-20mirnyj.ru/media/image/244442810-sous-chesnochnyj-600x600.png",
        "image_s": "https://4-20mirnyj.ru/media/image_s/244442810-sous-chesnochnyj-600x600.png",
        "category_name": "Топпинги",
        "description": "_",
        "status": "available",
        "calories": "200.0",
        "weight": "30.0",
        "proteins": "1.5",
        "fats": "10.0",
        "carbohydrates": "2.0"
    },
    {
        "id": 78,
        "name": "Пицца с ветчиной и грибами",
        "price": 660,
        "image": "https://4-20mirnyj.ru/media/image/%D0%BA%D0%B0%D0%BF%D1%80%D0%B8.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/%D0%BC_%D0%BA%D0%B0%D0%BF%D1%80%D0%B8.webp",
        "category_name": "Пицца",
        "description": "Моцарелла, ветчина, шампиньоны, маслины, пармезан (30 см)",
        "status": "available",
        "calories": "260.0",
        "weight": "600.0",
        "proteins": "11.0",
        "fats": "6.0",
        "carbohydrates": "40.0"
    },
    {
        "id": 79,
        "name": "Пицца По-деревенски",
        "price": 690,
        "image": "https://4-20mirnyj.ru/media/image/logo_4_20.png",
        "image_s": "https://4-20mirnyj.ru/media/image_s/logo_4_20.png",
        "category_name": "Пицца",
        "description": "Моцарелла, ростбиф, ветчина, колбаски охотничьи, бекон, пепперони, курица, лук красный (30 см)",
        "status": "available",
        "calories": "260.0",
        "weight": "630.0",
        "proteins": "3.0",
        "fats": "16.0",
        "carbohydrates": "25.0"
    },
    {
        "id": 80,
        "name": "Пицца Барбекю",
        "price": 710,
        "image": "https://4-20mirnyj.ru/media/image/%D0%B1%D0%B1%D0%BA_%D1%84%D1%88_DBqqeqI.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/%D0%BC_%D0%B1%D0%B1%D0%BA_%D1%84%D1%88_gE2jvNw.webp",
        "category_name": "Пицца",
        "description": "Моцарелла, ветчина, колбаски охотничьи, бекон, огурец маринованный, соус барбекю (30 см)",
        "status": "available",
        "calories": "341.0",
        "weight": "650.0",
        "proteins": "13.6",
        "fats": "14.0",
        "carbohydrates": "40.1"
    },
    {
        "id": 82,
        "name": "Соус Терияки",
        "price": 45,
        "image": "https://4-20mirnyj.ru/media/image/512936242-sous-teriyaki-600x600.png",
        "image_s": "https://4-20mirnyj.ru/media/image_s/512936242-sous-teriyaki-600x600.png",
        "category_name": "Соусы",
        "description": "_",
        "status": "available",
        "calories": "54.0",
        "weight": "30.0",
        "proteins": "0.5",
        "fats": "0.0",
        "carbohydrates": "13.0"
    },
    {
        "id": 83,
        "name": "Салат с томатами и моцареллой",
        "price": 640,
        "image": "https://4-20mirnyj.ru/media/image/5c7f36630306a_600x600_fit_85_1c1c1e.png.webp",
        "image_s": "https://4-20mirnyj.ru/media/image_s/5c7f36630306a_600x600_fit_85_1c1c1e.png.webp",
        "category_name": "1Салаты",
        "description": "Томаты, моцарелла, черри, руккола, соус песто, соевый соус",
        "status": "available",
        "calories": "153.5",
        "weight": "320.0",
        "proteins": "6.2",
        "fats": "12.4",
        "carbohydrates": "3.6"
    }
]

// End of Selection
